<template>
  <div>
    <div v-if="isMobile === false">
      <div class="box" v-if="languageSelect==='zh'">
        <div class="ViewTitle">个人信息</div>
        <div class="contain">
          <el-form
              :label-position="labelPosition"
              label-width="100px"
              :model="form"
          >
            <el-form-item label="头像" class="avatorBox" required>
              <el-upload
                  class="avatar-uploader boxCardUploadImg"
                  :action="baseURL + '/common/upload'"
                  :show-file-list="false"
                  :on-success="handleAvatarSuccess"
                  :before-upload="beforeAvatarUpload"
                  :on-error="handleAvatarError"
                  accept=".png,.jpg,.jpeg"
              >
                <img :src="form.avatar ? baseURL + form.avatar : avator" class="avatar" />
                <div class="wrap">
                  <div>上传头像</div>
                </div>
              </el-upload>
            </el-form-item>
            <el-form-item label="昵称" required>
              <el-input v-model="form.username" max-length="20" placeholder="请输入昵称，最多可输入20字"></el-input>
            </el-form-item>
            <el-form-item label="姓名" required>
              <el-input v-model="form.realname" placeholder="请输入姓名"></el-input>
            </el-form-item>
            <el-form-item label="性别" required>
              <el-select v-model="form.sex" placeholder="请选择性别">
                <el-option
                    v-for="item in options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="出生年月" required>
              <el-date-picker
                  v-model="form.birthday"
                  type="date"
                  placeholder="选择日期"
              >
              </el-date-picker>
            </el-form-item>
            <el-form-item label="所在行业" required>
              <el-input
                  v-model="form.industry"
                  placeholder="请输入所在行业"
              ></el-input>
            </el-form-item>
            <el-form-item label="所属公司" required>
              <el-input
                  v-model="form.company"
                  placeholder="请输入所属公司"
              ></el-input>
            </el-form-item>
            <el-form-item label="职务" required>
              <el-input v-model="form.duty" placeholder="请输入职务"></el-input>
            </el-form-item>
            <el-form-item label="阅读兴趣" required>
              <el-input
                  v-model="form.interest"
                  placeholder="请输入阅读兴趣"
              ></el-input>
            </el-form-item>
            <div class="submit" @click="submit()">确认</div>
          </el-form>
        </div>
      </div>
      <div class="box" v-if="languageSelect==='en'">
        <div class="ViewTitle">Personal information</div>
        <div class="contain">
          <el-form
              :label-position="labelPosition"
              label-width="150px"
              :model="form"
          >
            <el-form-item label="Avatar" class="avatorBox" required>
              <el-upload
                  class="avatar-uploader boxCardUploadImg"
                  :action="baseURL + '/common/upload'"
                  :show-file-list="false"
                  :on-success="handleAvatarSuccess"
                  :before-upload="beforeAvatarUpload"
                  :on-error="handleAvatarError"
                  accept=".png,.jpg,.jpeg"
              >
                <img :src="form.avatar ? baseURL + form.avatar : avator" class="avatar" />
                <div class="wrap">
                  <div>Upload profile picture</div>
                </div>
              </el-upload>
            </el-form-item>
            <el-form-item label="Nickname" required>
              <el-input v-model="form.username" max-length="20" placeholder="Please enter a nickname, up to 20 words"></el-input>
            </el-form-item>
            <el-form-item label="Name" required>
              <el-input v-model="form.realname" placeholder="Please enter your name"></el-input>
            </el-form-item>
            <el-form-item label="Sex" required>
              <el-select v-model="form.sex" placeholder="Please select gender">
                <el-option
                    v-for="item in options"
                    :key="item.value"
                    :label="item.labelEn"
                    :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="Date of birth" required>
              <el-date-picker
                  v-model="form.birthday"
                  type="date"
                  placeholder="Select date"
              >
              </el-date-picker>
            </el-form-item>
            <el-form-item label="Industry" required>
              <el-input
                  v-model="form.industry"
                  placeholder="Please enter your industry"
              ></el-input>
            </el-form-item>
            <el-form-item label="Company" required>
              <el-input
                  v-model="form.company"
                  placeholder="Please enter your company"
              ></el-input>
            </el-form-item>
            <el-form-item label="Job" required>
              <el-input v-model="form.duty" placeholder="Please enter job title"></el-input>
            </el-form-item>
            <el-form-item label="Reading interest" required>
              <el-input
                  v-model="form.interest"
                  placeholder="Please enter your reading interest"
              ></el-input>
            </el-form-item>
            <div class="submit" @click="submitEn()">Confirm</div>
          </el-form>
        </div>
      </div>
    </div>
    <div v-else>
      <div class="m-info-page">
        <Header :title="$t('user.info')"></Header>
        <div class="m-info-page-content">
          <van-cell :title="$t('user.avatar')" required>
            <van-uploader  v-model="fileList" :after-read="afterRead" preview-size="40" :show-upload="!fileList.length" :max-size="2 * 1024 * 1024" @oversize="onOversize"  />
          </van-cell>
          <van-field v-model="form.username" :label="$t('user.username')" :placeholder="$t('user.usernameTip')" required input-align="right" />
          <van-field v-model="form.realname" :label="$t('user.realname')" :placeholder="$t('user.realnameTip')" required input-align="right" />
          <van-field v-model="sex" :label="$t('user.sex')" :placeholder="$t('user.sexTip')" required input-align="right" readonly @click="sexShow = true" />
          <van-field v-model="form.birthday" :label="$t('user.birthday')" :placeholder="$t('user.birthdayTip')" required input-align="right" readonly @click="calendarShow = true" />
          <van-field v-model="form.industry" :label="$t('user.industry')" :placeholder="$t('user.industryTip')" required input-align="right" />
          <van-field v-model="form.company" :label="$t('user.company')" :placeholder="$t('user.companyTip')" required input-align="right" />
          <van-field v-model="form.duty" :label="$t('user.duty')" :placeholder="$t('user.dutyTip')" required input-align="right" />
          <van-field v-model="form.interest" :label="$t('user.interest')" :placeholder="$t('user.interestTip')" required input-align="right" />

          <van-action-sheet v-model="sexShow" :actions="mOptions" @select="onSelect" close-on-click-action cancel-text="取消" />
          <van-calendar v-model="calendarShow" @confirm="onConfirm" :show-confirm="false" :min-date="minDate" :max-date="maxDate" />
          <div class="m-submit-btn" @click="($i18n.locale == 'en' ? submitEn() : submit())">{{ $t('common.confirm') }}</div>
        </div>
      </div>

      <Bottom></Bottom>
      <!-- <div class="box1" v-if="languageSelect==='zh'">
        <div style="position:absolute;width: 45px;height: 45px;margin-top: 12px" @click="slip()"><img src="../assets/slipleft.png"></div>

                <div class="ViewTitle">个人信息</div>
                <div class="contain">
                  <el-form
                      :label-position="labelPosition"
                      label-width="100px"
                      :model="form"
                  >
                    <el-form-item label="头像" class="avatorBox" required>
                      <el-upload
                          class="avatar-uploader boxCardUploadImg"
                          :action="baseURL + '/common/upload'"
                          :show-file-list="false"
                          :on-success="handleAvatarSuccess"
                          :before-upload="beforeAvatarUpload"
                          :on-error="handleAvatarError"
                          accept=".png,.jpg,.jpeg"
                      >
                        <img :src="form.avatar ? baseURL + form.avatar : avator" class="avatar" />
                        <div class="wrap">
                          <div>上传头像</div>
                        </div>
                      </el-upload>
                    </el-form-item>
                    <el-form-item label="昵称" required>
                      <el-input v-model="form.username" max-length="20" placeholder="请输入昵称，最多可输入20字"></el-input>
                    </el-form-item>
                    <el-form-item label="姓名" required>
                      <el-input v-model="form.realname" placeholder="请输入姓名"></el-input>
                    </el-form-item>
                    <el-form-item label="性别" required>
                      <el-select v-model="form.sex" placeholder="请选择性别">
                        <el-option
                            v-for="item in options"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                        >
                        </el-option>
                      </el-select>
                    </el-form-item>
                    <el-form-item label="出生年月" required>
                      <el-date-picker
                          v-model="form.birthday"
                          type="date"
                          placeholder="选择日期"
                      >
                      </el-date-picker>
                    </el-form-item>
                    <el-form-item label="所在行业" required>
                      <el-input
                          v-model="form.industry"
                          placeholder="请输入所在行业"
                      ></el-input>
                    </el-form-item>
                    <el-form-item label="所属公司" required>
                      <el-input
                          v-model="form.company"
                          placeholder="请输入所属公司"
                      ></el-input>
                    </el-form-item>
                    <el-form-item label="职务" required>
                      <el-input v-model="form.duty" placeholder="请输入职务"></el-input>
                    </el-form-item>
                    <el-form-item label="阅读兴趣" required>
                      <el-input
                          v-model="form.interest"
                          placeholder="请输入阅读兴趣"
                      ></el-input>
                    </el-form-item>
                    <div class="submit" @click="submit()">确认</div>
                  </el-form>
                </div>
              </div>
              <div class="box1" v-if="languageSelect==='en'">
                <div style="position:absolute;width: 45px;height: 45px;margin-top: 12px" @click="slip()"><img src="../assets/slipleft.png"></div>

                <div class="ViewTitle">Personal information</div>
                <div class="contain">
                  <el-form
                      :label-position="labelPosition"
                      label-width="100px"
                      :model="form"
                  >
                    <el-form-item label="Avatar" class="avatorBox" required>
                      <el-upload
                          class="avatar-uploader boxCardUploadImg"
                          :action="baseURL + '/common/upload'"
                          :show-file-list="false"
                          :on-success="handleAvatarSuccess"
                          :before-upload="beforeAvatarUpload"
                          :on-error="handleAvatarError"
                          accept=".png,.jpg,.jpeg"
                      >
                        <img :src="form.avatar ? baseURL + form.avatar : avator" class="avatar" />
                        <div class="wrap">
                          <div>Upload profile picture</div>
                        </div>
                      </el-upload>
                    </el-form-item>
                    <el-form-item label="Nickname" required>
                      <el-input v-model="form.username" max-length="20" placeholder="Please enter a nickname, up to 20 words"></el-input>
                    </el-form-item>
                    <el-form-item label="Name" required>
                      <el-input v-model="form.realname" placeholder="Please enter your name"></el-input>
                    </el-form-item>
                    <el-form-item label="Sex" required>
                      <el-select v-model="form.sex" placeholder="Please select gender">
                        <el-option
                            v-for="item in options"
                            :key="item.value"
                            :label="item.labelEn"
                            :value="item.value"
                        >
                        </el-option>
                      </el-select>
                    </el-form-item>
                    <el-form-item label="Date of birth" required>
                      <el-date-picker
                          v-model="form.birthday"
                          type="date"
                          placeholder="Select date"
                      >
                      </el-date-picker>
                    </el-form-item>
                    <el-form-item label="Industry" required>
                      <el-input
                          v-model="form.industry"
                          placeholder="Please enter your industry"
                      ></el-input>
                    </el-form-item>
                    <el-form-item label="Company" required>
                      <el-input
                          v-model="form.company"
                          placeholder="Please enter your company"
                      ></el-input>
                    </el-form-item>
                    <el-form-item label="Job" required>
                      <el-input v-model="form.duty" placeholder="Please enter job title"></el-input>
                    </el-form-item>
                    <el-form-item label="Reading interest" required>
                      <el-input
                          v-model="form.interest"
                          placeholder="Please enter your reading interest"
                      ></el-input>
                    </el-form-item>
                    <div class="submit" @click="submitEn()">Confirm</div>
                  </el-form>
                </div>
              </div> -->
    </div>

  </div>
</template>
        <script>
        import { userDetail, userUpdate, UploadData } from "@/api/user";
        import { shareUrl } from "@/utils/vxshare.js";
        import Bottom from "@/components/mobile/Bottom.vue";
        import Header from "@/components/mobile/Header.vue";
        import {  Field, Uploader, Cell, ActionSheet, Calendar, Toast } from 'vant';
        import mixins from "@/utils/mixins.js";

        export default {
          name: "Info",
          components: { 
            [Field.name]: Field, 
            [Uploader.name]: Uploader, 
            [Cell.name]: Cell,
            [ActionSheet.name]: ActionSheet,  
            [Calendar.name]: Calendar,  
            [Toast.name]: Toast,  
            Bottom,
            Header
          },
          mixins: [mixins],
          data() {
            return {
              isMobile: false,
              languageSelect:'',
              // baseURL: this.$utils.baseURL,
              labelPosition: "left",
              avator: require("@/assets/avator.png"),
              options: [
                {
                  value: "1",
                  label: "男",
                  labelEn:'Boy'
                },
                {
                  value: "2",
                  label: "女",
                  labelEn:'Girl'
                }
              ],
              mOptions: [
                {
                  name: '男',
                  value: "1",
                },
                {
                  name: '女',
                  value: "2",
                }
              ],
              file: {},
              form: {
                avatar: "",
                username: "",
                realname: "",
                sex: "",
                birthday: "",
                industry: "",
                company: "",
                duty: "",
                interest: "",
              },
              fileList: [],
              calendarShow: false,
              sexShow: false,
              sex: '',
              minDate: new Date('1970-01-01'),
              maxDate: new Date()
            };
          },
          computed: {},
          created() {
            if(localStorage.getItem('language')){
              this.languageSelect=localStorage.getItem('language')
            }else{
              this.languageSelect='zh'
            }
            console.log(this.$route)
            localStorage.setItem("myclick",1)
            this.getUser();
            let url = window.location.host
            // let url = 'https://oilgasinfoai.com/?language=zh'
            setTimeout(() => {
              //encodeURIComponent 将你的URL进行转译一下，因为微信分享出去后会带有多余用不到的参数
              let shareData = {
                url:url,
                title:'油气知识',
                text:'洞悉油气行业变化，体验AI大模型，就来油气知识！',
              }
              shareUrl(shareData);
            }, 1000);
          },
          methods: {
            onClickLeft(){
              this.$router.go(-1)
            },
            onSelect(item){
              this.sex = item.name
            },
            formatDate(date) {
              return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
            },
            onConfirm(date){
              this.calendarShow = false
              this.form.birthday = this.formatDate(date)
            },
            onOversize(){
              if(this.$i18n.locale == 'zh'){
                Toast('文件大小不能超过 2MB!');
              }else{
                Toast('File size exceeds 2MB!');
              }
              
            },
            async afterRead(file){
              let res = await UploadData({
                file: file.file
              })
              this.form.avatar = res.fileName
            },
            slip(){
              this.$router.go(-1)
            },
            getUser() {
              userDetail().then((res) => {
                this.form = res.data;
                if(res.data.sex){
                  let temp = this.mOptions.find(item => item.value == res.data.sex)
                  if(temp){
                    this.sex = temp.name
                  }
                }
                this.fileList = res.data.avatar ? [
                  {
                    url: this.baseURL + res.data.avatar
                  }
                ] : []
              });
            },

            submitEn() {
              if(this.sex){
                this.form.sex = this.mOptions.find(item => item.name == this.sex).value
              }

              if (!this.form.username) {
                this.$message.error("Please enter a nickname");
                return false;
              }
              if (!this.form.realname) {
                this.$message.error("Please enter your name");
                return false;
              }
              if (!this.form.sex) {
                this.$message.error("Please select gender");
                return false;
              }
              if (!this.form.birthday) {
                this.$message.error("Please set your birthday");
                return false;
              }
              if (!this.form.industry) {
                this.$message.error("Please enter your industry");
                return false;
              }
              if (!this.form.company) {
                this.$message.error("Please enter your company");
                return false;
              }
              if (!this.form.duty) {
                this.$message.error("Please enter job title");
                return false;
              }
              if (!this.form.interest) {
                this.$message.error("Please enter your reading interest");
                return false;
              }
              this.form.access_token = localStorage.getItem('Zyaccess_token')

              userUpdate(this.form).then((res) => {
                localStorage.setItem("typeInfo", 1);
                this.$store.state.typeInfo = 1;
                this.$message({
                  message: res.msg, //提示的信息
                  type: "success", //类型是成功
                  duration: 1200, //显示时间, 毫秒。设为 0 则不会自动关闭，建议1200
                  onClose: () => {
                    //跳转页面或执行方法
                    // location.reload();
                    this.$router.back()
                  },
                });
              });
            },
            submit() {
              if (!this.form.username) {
                this.$message.error("请输入昵称");
                return false;
              }
              if (!this.form.realname) {
                this.$message.error("请输入姓名");
                return false;
              }
              if (!this.form.sex) {
                this.$message.error("请选择性别");
                return false;
              }
              if (!this.form.birthday) {
                this.$message.error("请选择出生年月");
                return false;
              }
              if (!this.form.industry) {
                this.$message.error("请输入所在行业");
                return false;
              }
              if (!this.form.company) {
                this.$message.error("请输入所属公司");
                return false;
              }
              if (!this.form.duty) {
                this.$message.error("请输入职务");
                return false;
              }
              if (!this.form.interest) {
                this.$message.error("请输入阅读兴趣");
                return false;
              }
              this.form.access_token = localStorage.getItem('Zyaccess_token')
              
              userUpdate(this.form).then((res) => {
                  localStorage.setItem("typeInfo", 1);
                  this.$store.state.typeInfo = 1;
                this.$message({
                  message: res.msg, //提示的信息
                  type: "success", //类型是成功
                  duration: 1200, //显示时间, 毫秒。设为 0 则不会自动关闭，建议1200
                  onClose: () => {
                    //跳转页面或执行方法
                    location.reload();
                  },
                });
              });
            },
            handleAvatarSuccess(res) {
              this.form.avatar = res.fileName;
            },
            beforeAvatarUpload(file) {
              const isLt2M = file.size / 1024 / 1024 < 2;
              if (!isLt2M) {
                this.$message.error("上传图片大小不能超过 2MB!");
              }
              return isLt2M;
            },
            // 文件上传失败（http）
            handleAvatarError() {
              this.$message.error("上传失败! （http失败）");
            },
          },
          mounted() {
            // Check if the device width is less than 768px (example for mobile)
            this.isMobile = window.matchMedia("(max-width: 767px)").matches;
          },
        };
        </script>
        <style scoped lang="scss">
        .box {
          background: #fff;
          .ViewTitle {
            height: 67px;
            line-height: 67px;
            border-bottom: 1px solid #ebebeb;
            font-size: 20px;
            color: #333333;
            font-weight: 400;
            padding: 0 30px;
          }
          .contain {
            margin: 0 30px;
            padding-bottom: 64px;
            padding-top: 30px;
            .submit {
              width: 320px;
              height: 56px;
              line-height: 56px;
              text-align: center;
              font-size: 18px;
              color: #ffffff;
              border-radius: 28px;
              background: var(--main-color);
              margin-left: 100px;
              cursor: pointer;
              margin-top: 56px;
            }
          }
          .avatar-uploader-icon {
            font-size: 28px;
            color: #8c939d;
            width: 100px;
            height: 100px;
            line-height: 100px;
            text-align: center;
          }
          .avatar,
          .boxCardUploadImg,
          .box .avatar-uploader-icon,
          .el-upload {
            width: 72px !important;
            height: 72px !important;
            display: block !important;
            border-radius: 36px;
          }
          .el-upload {
            position: relative;
            overflow: hidden;
          }

          .boxCardUploadImg .el-upload div.wrap {
            // display: none;
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: flex-end;
            flex-direction: column;
            border-radius: 100%;
            overflow: hidden;
            position: absolute;
            left: 0;
            bottom: 0;
            right: 0;
            margin: 0 auto;
            div {
              width: 72px;
              height: 24px;
              line-height: 20px;
              color: #ffffff;
              font-size: 10px;
              cursor: pointer;
              background: rgba($color: #000000, $alpha: 0.4);
              overflow: hidden !important;
            }
          }
        }
        .box1 {
          background: #fff;
          width: 93vw;
          margin:5px auto;
          overflow-x: hidden;
          .ViewTitle {
            width: 99vw;
            height: 67px;
            line-height: 67px;
            border-bottom: 1px solid #ebebeb;
            font-size: 20px;
            color: #333333;
            font-weight: 400;
            padding: 0 50px;
          }
          .contain {
            margin: 0 15px;
            padding-bottom: 64px;
            padding-top: 30px;
            .submit {
              width: 80vw;
              height: 56px;
              line-height: 56px;
              text-align: center;
              font-size: 18px;
              color: #ffffff;
              border-radius: 28px;
              background: var(--main-color);
              cursor: pointer;
              margin: 56px auto;
            }
          }
          .avatar-uploader-icon {
            font-size: 28px;
            color: #8c939d;
            width: 100px;
            height: 100px;
            line-height: 100px;
            text-align: center;
          }
          .avatar,
          .boxCardUploadImg,
          .box .avatar-uploader-icon,
          .el-upload {
            width: 72px !important;
            height: 72px !important;
            display: block !important;
            border-radius: 36px;
          }
          .el-upload {
            position: relative;
            overflow: hidden;
          }

          .boxCardUploadImg .el-upload div.wrap {
            // display: none;
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: flex-end;
            flex-direction: column;
            border-radius: 100%;
            overflow: hidden;
            position: absolute;
            left: 0;
            bottom: 0;
            right: 0;
            margin: 0 auto;
            div {
              width: 72px;
              height: 24px;
              line-height: 20px;
              color: #ffffff;
              font-size: 10px;
              cursor: pointer;
              background: rgba($color: #000000, $alpha: 0.4);
              overflow: hidden !important;
            }
          }
          ::v-deep {
            .contain {
              .el-select {
                width: 100%;
              }
              .el-input__inner {
                line-height: 50px;
                height: 50px;
                font-size: 16px;
                background: inherit;
                color: #333;
              }
              .el-input__inner::placeholder {
                color: #bbbbbb;
              }

              .el-input.is-active .el-input__inner,
              .el-input__inner:focus {
                border-color: #dcdfe6;
              }
              .el-form-item {
                width: fit-content;
                margin-bottom: 36px;
              }
              .el-date-editor.el-input,
              .el-date-editor.el-input__inner {
                width: 100%;
              }
              .el-form-item__content {
                width:60vw;
                background: #f5f5f7;
                border-radius: 4px;
              }
              .avatorBox .el-form-item__content {
                width: auto;
                background: none;
                border-radius: 100%;
              }
              .el-input__prefix {
                left: auto;
                right: 5px;
                .el-input__icon.el-icon-date {
                  transform: rotate(180deg);
                  font-size: 20px;
                  &:before {
                    content: "\e6e1";
                  }
                }
              }
              .el-input--prefix .el-input__inner {
                padding-left: 15px;
              }
              .el-select__caret {
                font-size: 20px;
              }
            }
          }
        }
        ::v-deep {
          .contain {
            .el-select {
              width: 100%;
            }
            .el-input__inner {
              line-height: 50px;
              height: 50px;
              font-size: 16px;
              background: inherit;
              color: #333;
            }
            .el-input__inner::placeholder {
              color: #bbbbbb;
            }

            .el-input.is-active .el-input__inner,
            .el-input__inner:focus {
              border-color: #dcdfe6;
            }
            .el-form-item {
              width: fit-content;
              margin-bottom: 36px;
            }
            .el-date-editor.el-input,
            .el-date-editor.el-input__inner {
              width: 100%;
            }
            .el-form-item__content {
              width: 400px;
              background: #f5f5f7;
              border-radius: 4px;
            }
            .avatorBox .el-form-item__content {
              width: auto;
              background: none;
              border-radius: 100%;
            }
            .el-input__prefix {
              left: auto;
              right: 5px;
              .el-input__icon.el-icon-date {
                transform: rotate(180deg);
                font-size: 20px;
                &:before {
                  content: "\e6e1";
                }
              }
            }
            .el-input--prefix .el-input__inner {
              padding-left: 15px;
            }
            .el-select__caret {
              font-size: 20px;
            }
          }
        }
        input {
          border: none;
          outline: none;
        }
        .box .avatar-uploader-icon {
          width: 72px !important;
          height: 72px !important;
          display: block !important;
          border-radius: 36px;
          line-height: 72px;
        }




        .m-info-page{
          min-height: 90vh;
          background-color: #f8f8f8;
          
          .m-info-page-content{
            background-color: #fff;
            margin: 0 20px;
            overflow: hidden;
            ::v-deep .van-uploader{
              vertical-align: top;
              .van-uploader__upload, .van-uploader__preview{
                margin: 0;
              }
            }
            .van-cell{
              align-items: center;
            }
            .m-submit-btn{
              margin: 20px;
              color: #fff;
              line-height: 40px;
              text-align: center;
              border-radius: 30px;
              background-color: var(--main-bg-color);
            }
          }
        }
        </style>

